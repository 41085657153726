import React from "react";
import { graphql, Link } from "gatsby";
import { DefaultLayout, SidebarLayout } from "../layouts";
import { Hero, LinkBox } from "../components";
import { Body } from "../layouts/SidebarLayout/SidebarLayout";
import { Link as EEALink } from "../components/LinkBox/LinkBox";

export type AboutPageProps = {
  hero: {
    image: string;
    text: string;
  };
  body: string;
  sidebar: {
    linkBox: {
      title: string;
      links: EEALink[]
    }
  }
};

export const AboutPageTemplate = (data: any) => {
  const page = data.page;
  const about = data.about;
  return (
    <DefaultLayout>
      <Hero showBox={true} image={about.hero.image} boxText={about.hero.text} />

      <SidebarLayout
        body={
          <Body dangerouslySetInnerHTML={{ __html: page.html }} />
        }
        sidebar={
          <LinkBox
            title={about.sidebar.linkBox.title}
            color="#000000"
            links={about.sidebar.linkBox.links}
          />
        }
      />
    </DefaultLayout>
  );
}

export const query = graphql`
  {
    about: aboutJson {
      hero {
        image
        text
      }
      sidebar {
        linkBox {
          title
          links {
            text
            url
          }
        }
      }
    }
    page: markdownRemark(fileAbsolutePath: { regex: "/(about.md)/" }) {
      html
      frontmatter {
        hero {
          showBox
          boxText
          image
        }
        sidebar {
          boxes {
            title
            color
            links {
              text
              url
              isExternal
            }
          }
        }
      }
    }
  }
`;

export default ({ data }) => (
  <AboutPageTemplate {...data} />
);
